// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ward-1-js": () => import("./../src/pages/about-ward-1.js" /* webpackChunkName: "component---src-pages-about-ward-1-js" */),
  "component---src-pages-get-involved-js": () => import("./../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-homelessness-js": () => import("./../src/pages/issues/homelessness.js" /* webpackChunkName: "component---src-pages-issues-homelessness-js" */),
  "component---src-pages-issues-housing-js": () => import("./../src/pages/issues/housing.js" /* webpackChunkName: "component---src-pages-issues-housing-js" */),
  "component---src-pages-issues-immigration-js": () => import("./../src/pages/issues/immigration.js" /* webpackChunkName: "component---src-pages-issues-immigration-js" */),
  "component---src-pages-issues-public-safety-js": () => import("./../src/pages/issues/public-safety.js" /* webpackChunkName: "component---src-pages-issues-public-safety-js" */),
  "component---src-pages-issues-small-business-js": () => import("./../src/pages/issues/small-business.js" /* webpackChunkName: "component---src-pages-issues-small-business-js" */),
  "component---src-pages-issues-youth-investment-js": () => import("./../src/pages/issues/youth-investment.js" /* webpackChunkName: "component---src-pages-issues-youth-investment-js" */),
  "component---src-pages-tonys-story-js": () => import("./../src/pages/tonys-story.js" /* webpackChunkName: "component---src-pages-tonys-story-js" */),
  "component---src-pages-tonys-vision-js": () => import("./../src/pages/tonys-vision.js" /* webpackChunkName: "component---src-pages-tonys-vision-js" */)
}

